<template>
  <div class="kecheng">
    <!-- 盒子 -->

    <kind-choose-view :showKc="false" :showZy="false" :type="115" @isOk="isOk" />

    <!-- 内容 -->
    <div class="kecent">
      <div class="kecents">
        <div class="kecentsBox">
          <div
            class="kecentsBoxss pointer"
            v-for="(item, indc) in list"
            :key="indc"
            @click="allopenclas(item)"
          >
            <div class="kecentsBoxs">
              <img :src="item.thumbnail" />
              <div class="kecentsBoxsB">
                <p>{{ item.title }}</p>
                <div class="kecentsBoxsBp">
                  <div class="kecentsBoxsBpl">主讲老师：{{ item.terName ? item.terName : "" }}</div>
                  <div class="kecentsBoxsBpl">
                    {{ item.startEnd ? item.startEnd.slice(0, 19) : "" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <el-pagination
            class="tabPagination"
            :total="total"
            :current-page="pageNum"
            :page-sizes="[8, 16, 24, 40]"
            :page-size="pageSize"
            :pager-count="5"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            background
            layout="total, sizes, prev, pager, next"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 暂无课程 -->
    <div class="nothing" v-if="!list || list.length == 0">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
  </div>
</template>
<script>
import KindChooseView from "@/components/kindChooseView.vue";
import { Know } from "@/api/know";
let know = new Know();
import { getInfo } from "@/api/cookies";
export default {
  components: { KindChooseView },
  data() {
    return {
      search: {},
      userInfo: null,
      list: [],
      pageSize: 8,
      pageNum: 1,
      total: 0,
    };
  },
  created() {
    this.userInfo = getInfo();
    // 调用轮播图导航接口方法
    this.getList();
  },
  methods: {
    // 获取列表
    getList() {
      know
        .getLiveshowpublicByTypes(
          this.pageSize,
          this.pageNum,
          this.search.kind ? this.search.kind : null,
          this.search.type
        )
        .then((res) => {
          this.total = res.total;
          this.list = res.rows;
        });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    // 筛选
    isOk(val) {
      this.search = val;
      this.getList();
    },
    // 公开课详情
    allopenclas(item) {
      this.$router.push(`/openclassxq?id=${item.id}`);
    },
  },
};
</script>
<style lang="less" scoped>
.kecheng {
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  overflow: hidden;
  // 内容
  .kecent {
    /*   width: calc(65% + 22px); */
    width: calc(1200px + 22px);
    height: auto;
    margin: auto;
    margin-top: 30px;
    background: #f5f7fa;
    .kecents {
      width: 100%;
      height: 100%;
      margin-left: 11px;
      .kecentsBox {
        width: 100%;
        height: 100%;
        margin-bottom: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        .kecentsBoxss {
          width: 284px;
          margin-right: 21px;
          margin-bottom: 21px;
          height: 260px;
          .kecentsBoxs {
            width: 284px;
            margin: auto;
            height: 260px;
            img {
              width: 100%;
              height: 160px;
            }
            .kecentsBoxsB {
              width: 100%;
              height: 100px;
              padding: 14px;
              position: relative;
              background: #ffffff;
              p {
                width: 100%;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #27323f;
              }

              .kecentsBoxsBp {
                margin-top: 15px;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                position: absolute;
                bottom: 13px;
                left: 13px;
                right: 14px;
                .kecentsBoxsBpl {
                  width: auto;
                  height: auto;
                  font-size: 12px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #999999;
                  line-height: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.isNull {
  width: 1200px;
  margin: 0px auto;
}
</style>
